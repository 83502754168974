/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Ghalia Adzana
==============================================================================================*/
@import "sass-lib";

body {  
    color: #333;
    font-size: 16px;
    font-family: $lt; 
    position: relative;
    overflow-x: hidden;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }
/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper.new {width: 1600px;margin: 0 auto;}
.wrapper1360{width: 1360px; margin: 0 auto;}
.btn-fortuna-2 { position: relative; top: 10px; }
.btn-blue{}
.btn-knj{ 
    cursor: pointer; text-align: center; width: 100%; line-height: 60px; font-size: 20px;
     background:#959595; border:none; color: #fff; font-weight: 600; @include transition(all ease-out 0.3s);
    &:hover{
        background:#c1c1c1;
    }
}
.btn-link-news{ 
    cursor: pointer; text-align: center; width: 100%; 
    line-height: 60px; font-size: 20px; border: none; background: #c1c1c1; color: #fff; text-transform: uppercase; @include transition(all ease-out 0.3s);
    &:hover{ background:#959595; }
}
.btn-copy{
    width: 110px; height: 60px; background: #cccccc;font-size: 16px; color: #333333; @include boxShadow(none); border: none; font-weight: 700; margin-bottom: 28px; @include borderRadius(3px); cursor: pointer; @include transition(0.2s all); max-width: 100%;
    &:hover{background: #4e4e4e; color: #fff;}
}
.btn-oval{font-size: 13px; color: #fff; font-weight: 900; background: $c_global; height: 34px; line-height: 34px; padding: 0 30px; @include borderRadius(30px); border: none; cursor: pointer;
    @include transition(0.2s all); display: inline-block;
    &:hover{background: #21646d; color: #fff;}
    &.padd-plus{padding-left: 50px; padding-right: 50px;}
    &.btn-fix{width: 170px; padding: 0;}
    &.btn-submit-finance{margin-right: 40px;}
    &.btn-auto{width: auto;}
}
/* header
----------------------------------------------------------------------------------------------*/
header.new{
    position: relative;
    @include afterclear;
    .line{
        width: 100%; position: absolute; height: 6px; left: 0; top: 0; background:#63bdc8;
        &:before{
            content:''; width: 510px; height: 6px; background:#cd2026; position: absolute; top: 0;
        }
    }
    .left-header{
        float: left; margin-top: 35px;
    }
    .right-header{
        float: right; margin-top: 10px;
    }
}

/* middle
----------------------------------------------------------------------------------------------*/
.box-home.new{
    margin: 0 -35px; padding-bottom:55px;
    @include afterclear;
    .list-box{
        width: 33.333%; float: left; padding: 0 35px;  @include boxSizing(border-box); position: relative;
    }
    .reksa-home{

        h5{ 
            font-size: 20px; text-transform: uppercase; font-weight: 700; font-family: $lt; border-bottom: 1px solid #36a5b3; padding-bottom: 20px; 
            margin-bottom: 15px;
        }
    }

}
.new.wrap-dana{
    margin-bottom: 25px; height: 515px; overflow: auto;
    .list{
        margin-bottom: 10px; border-bottom: 1px solid #d7d7d7; padding-bottom: 15px; font-size: 16px;
        h6{ font-size: 16px; color: #333; font-weight: 500;}
        .row{ 
            margin-bottom: 10px;
            &:last-child{ margin-bottom:0; }
        }
        .right{
            color: #999999; font-weight: 700;
            span{ color:#00a345;  }
        }
    }
}
.login.new{
     background: #f4f4f4; padding: 50px 45px 100px;box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.2);  @include boxSizing(border-box);
    .row{ margin-bottom:15px;  }
    span{ font-size:20px; font-weight: 600;  text-align: center;  display: block; margin-bottom: 45px;}
    label{ display: block; font-size: 16px; color: #999999; margin-bottom: 15px; }
    input[type='text'],input[type='password'],input[type='email']{ width: 100%; height: 60px; font-size: 22px; @include boxSizing(border-box); }
    input[type='submit']{
         width: 100%; line-height: 60px; font-size: 18px; font-family: $ub; font-weight: 700; 
        text-transform: uppercase; background: #e2211a; color: #fff; padding: 0; cursor: pointer;  @include transition(all ease-out 0.25s);
        &:hover{ background:#e0403a; }
    }
    &.forgot{
        position: absolute; left: 35px;right:35px; top: 0; bottom: 0; box-shadow: none; visibility: hidden; opacity: 0;
        .left{
            width: 110px;
            span{ 
               text-align: left; font-size:16px; color: #999; 
                a{ color:#36a5b3; font-weight: 700; font-size: 16px; display: block; }
                
            }
        }
        .right{
            width: 160px; text-align: right; display: inline-block;
            span{ 
                 text-align: right; font-size:16px; color: #999;
                a{ color:#36a5b3; font-weight: 700; font-size: 16px; display: block;  }
            }
        }
        &.active{
            visibility: visible; opacity: 1;
        }
    }
}
.link-forgot{
    text-align: center; font-size: 16px; font-weight: 300; font-style: italic; margin-bottom: 25px;  color: #999999;
    display: block;
}
.register{
    text-align: center; margin-bottom: 30px; font-size:16px; color: #666666; font-weight: 500; display: block;
    a{ color:#e2211a;  }

}
.slider-home{
    position: relative;
    .slick-dots{
        position: absolute; bottom: 25px; left: 0; right: 0; display: inline-block; margin: 0 auto; text-align: center;
       li{
        display: inline-block; border:2px solid #726a63; @include borderRadius(100%); margin: 0 5px; width: 12px; height:12px;  position: relative;
            button{ 
                border:none; background: transparent; text-indent: -999999px; cursor: pointer;
                &:before{ 
                    content:''; width: 8px; height: 8px; @include borderRadius(100%); background:transparent;
                    position: absolute; right: 0; left: 0; right: 0; bottom: 0; margin:auto; top: 0;
                 }
            }

            &.slick-active{
                border-color: #369fad;
                button{
                    &:before{ background:#36a5b3; }
                }
            }
        }
    }
}


.middle-content{
    padding: 70px 0 150px;
    .wrapper{ width: 1110px; @include afterclear; }
    h4{ font-size:24px; font-weight: 700; color: #333333; margin-bottom: 30px;   }
    h2{ font-size: 36px; font-weight: 300; color:#333; margin-bottom: 45px;  }
    &.padd20{ padding: 20px 0; }
    &.nopadd{ padding: 0; }
    .images{
        position: relative; 
        img{ width: 100%; }
    }
    .desc{
        position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; width: 790px; text-align: center; height: 250px;
        p{
            font-size:22px; color: #fff; font-weight: 300; line-height: 30px;
        }
    }
    .ty{ font-size:55px; font-weight: 700; color: #fff; display: block; margin-bottom:30px;  }
    .title-form{
        @include fullinline; height: 60px; line-height: 60px; background: #36a5b3; color: #fff; font-size: 18px; font-weight: 700; text-align: center; margin-bottom: 32px;
    }
    label{font-weight: 700;font-size: 16px; line-height: 20px; color: #888888; margin-bottom: 28px; display: block;}
    span{
        /*color: #888888; font-weight: 700;*/
        &.notes{
            font-size: 16px; line-height: 20px; color: #cd2026; font-weight: 300; font-style: italic; margin-bottom: 28px; display: block;
        }
        &.ls{margin-right: 28px;}
    }
    hr{
        @include fullinline; height: 1px; background: #cccccc; border: none; margin: 30px 0;
        &.dotted{background: transparent; border-top: 1px dotted #cccccc;}
        &.dashed{background: transparent; border-top: 1px dashed #cccccc;}
        &.style2{margin: 0 0 50px;}
        &.style3{margin: 15px 0px;}
        &.hrmargtop{margin: 0 0 20px; background: #e1e1e1;}
    }
    span{
        color: #777777; font-size: 14px; line-height: 20px;
        &.bbold{font-weight: 700;}
    }
    form{
        @include fullfloat; margin: 20px 0;
    }
    input[type="text"], input[type="password"], input[type="email"], input[type="file"], select, textarea{
        @include fullfloat; height: 60px; border: 1px solid #cccccc; @include borderRadius(5px); font-size: 16px; font-weight: 400; color: #333333; padding-left: 20px; padding-right: 20px; margin-bottom: 28px;
        &:focus, &:active{border-color: #36a5b3;}
    }
    input[type="radio"]{
        background: url(../images/material/ic_radio.png) no-repeat bottom center; display: inline-block; vertical-align: middle; margin:0; padding: 0; width:16px; height:16px; cursor: pointer; font-size:16px;line-height:18px;
        &:checked{background-position: top center;}
    }
    input[type=image] {border: none;padding: 5px;width: 25px;}
    select{
        padding: 0 35px 0 20px;
        &.sel_blue{
            background-image: url('../images/material/arrow-bottom-blue.png'); font-weight: 700; color: #36a5b3; font-size: 18px; border: none; min-width: 160px; width: auto; float: none; height: auto; margin-bottom: 0; padding: 0 32px 0 10px;
        }
    }
    input[type="text"],input[type="password"],input[type="email"],input[type="file"],select{
        @include boxSizing(border-box);
     } 
    .s_col_inline{
        span{height: 60px; line-height: 60px; display: inline-block; width: 100%; margin-bottom: 28px; color: #888;}
        a{color: #36a5b3;}
        &.sbtm{
            span{height: auto; line-height: 1; position: relative; top: -15px;}
        }
    }
    .cost-notes{
        border: 1px solid #36a5b3; @include fullinline; @include borderRadius(5px); padding: 22px 12px; margin-bottom: 28px; @include boxSizing(border-box);
        .col{margin-bottom: 18px;
            &.nomarg{margin: 0;}
        }
    }
    .textstyle{
        @include fullfloat; position: relative;
        input[type="text"], input[type="password"], input[type="email"], input[type="file"]{
            padding-left: 60px; padding-right: 20px;
        }
        &:before{content: ""; position: absolute; top: 20px; left: 22px; width: 18px; height: 22px;}
        &.ts-user{
            &:before{background: url('../images/material/ic-user.png') no-repeat center;}
        }
        &.ts-pass{
            &:before{background: url('../images/material/ic-pass.png') no-repeat center;}
        }
        &.ts-date{
            &:before{background: url('../images/material/ic-date.png') no-repeat center; width: 23px; height: 23px;}
            .ui-widget.ui-widget-content{position: absolute; top: 60px; left: 0; z-index: 2; display: none;}
        }
        &.ts-right{
            input[type="text"], input[type="password"], input[type="email"], input[type="file"]{
                padding-right: 60px; padding-left: 20px;
            }
            &:before{left: auto; right: 22px;}
        }
        &.ts-none{
            input[type="text"], input[type="password"], input[type="email"], input[type="file"]{
                padding-right: 20px; padding-left: 20px;
            }
        }
        &.ts-nominal{
            height: 60px; line-height: 60px; margin-bottom: 28px;text-align: center;
            input{width: 140px; max-width: 100%; height: 25px; background: #dddddd; color: #555555; font-size: 14px; font-weight: 700; @include borderRadius(20px); text-align: right; margin: 0; display: inline-block; float: none;}
        }
        .noted{
            position: absolute; left: 100%; margin-left: 10px; font-size: 16px; line-height: 26px;width: 100%; height: 60px; line-height: 60px; font-weight: 300;
            &.noted-error{color: #cd2026; font-weight: 700; margin-left: 30px;}
            &.noted-tooltip-bottom{
                left: 0; top: 100%;height: auto; line-height: 1.3; margin-top: -28px; margin-left: 0; padding-top: 10px; font-weight: 700; display: none;
                &:before{content: ""; border-bottom: 10px solid $c_ea; border-left: 10px solid transparent; border-right: 10px solid transparent; position: absolute; top: 0; left: 20px;}
                span{display: block; background: $c_ea; padding: 20px; @include borderRadius(5px);}
            }
        }
    }
    .btn-htab{display: inline-block;margin-bottom: 15px;
        a{
            float: left; padding: 0 0 7px; position: relative; color: #555555; margin-bottom: 15px; font-size: 16px;
            &:first-child{padding-left: 0;}
            &:last-child{padding-right: 0;}
            &::after{content: "";position: absolute; bottom: 0; left: 0; right: 0; height: 3px; background: $c_global; opacity: 0;}
            &.active{font-weight: 700; color: $c_global;
                &::after{opacity: 1;}
            }
            &:hover{color: $c_global;
                &::after{opacity: 1;}
            }
            &.active + span,&:hover + span{color: $c_global;}
        }
        span{padding: 0 13px; float: left; padding-bottom: 7px; margin-bottom: 15px;}
    }
    .opt{
        font-size:16px;line-height:18px;position:relative; padding: 0px 140px 0 25px;text-align: justify; margin: 8px 0 5px; display: inline-block;
        .radio-custom, 
        .check-custom {
            width: 15px;height: 15px;float: none;margin: 2px 10px 0 0;position: absolute !important;top:0;left:0;
            display: inline-block; vertical-align: middle;              
        }
        .check-custom {
            background: url(../images/material/ico_checkbox.png) no-repeat bottom center;
            input[type="checkbox"]{cursor: pointer;}
            &.active{background-position: top center;}
        }
        /*.radio-custom{
            background: url(../images/material/ico_radio.png) no-repeat bottom center;
            &.active{background-position: top center;}
        }*/
        
        &.style2{
            .check-custom {
                background: url(../images/material/ico_checkbox_2.png) no-repeat bottom center;
                &.active{background-position: top center;}
            }
        }

        a{font-weight: 700; color: #36a5b3;}
        b{opacity: 0;position: absolute;top:0;right: 0;}
        &.checkboxstyle{
            position: relative; margin: 0; cursor: pointer; padding: 10px 0 10px 25px;
            input[type=checkbox]{
                background: url(../images/material/ico_checkbox.png) no-repeat bottom center; display: inline-block; vertical-align: middle; margin:0; padding: 0; width:15px; height:15px !important; position:absolute !important; top:10px
                ; left:0; cursor: pointer;
                &:checked{background-position: top center;}
            }
            label{margin-bottom: 0;}
        }
    }

    .radiostyle {
        position: relative; margin: 0; cursor: pointer; padding: 10px 0 10px 25px;
        input[type=radio]{
            background: url(../images/material/ico_radio.png) no-repeat bottom center; display: inline-block; vertical-align: middle; margin:0; padding: 0; width:15px; height:15px !important; position:absolute !important; top:3px !important; left:0; cursor: pointer;
            &:checked{background-position: top center;}
        }
        label{margin-bottom: 0;}

        &.nopadd{padding: 0 0 0 25px;}
    }  
    .breadcrumb{
        @include fullfloat; border-bottom: 1px solid #e1e1e1; padding-bottom: 30px; margin-bottom: 50px; @include afterclear;
        &.no-border { border-bottom: 0px solid transparent; }
        .box-breadcrumb{
            float: left; position: relative;padding: 0 0 3px;
            a{
                font-size: 18px; font-weight: 700; position: relative; text-transform: uppercase; color: #111111;@include transition(0.2s all);
                &:hover{color: #36a5b3;}
                i{width: 26px; height: 26px; background: url('../images/material/arr-breadcrumb.png') no-repeat center; display: inline-block; position: relative;top: 5px;margin-left: 20px;}
            }
            ul{
                position: absolute; top: 100%; z-index: 10; left: 0; min-width: 100%; background: #eeeeee; border-bottom: 3px solid #36a5b3;border-top: 3px solid #36a5b3;
                padding: 15px 10px 15px 25px; display: none;
                li{
                    margin-bottom: 12px;
                    a{
                        font-size: 14px; line-height: 20px; font-weight: 700; color: #777777;@include transition(0.2s all);
                        &:hover{color: #36a5b3;}
                    }
                    &:last-child{margin-bottom: 0;}
                    &.active{
                        padding-left: 16px; position: relative;
                        &:before{content: "";position: absolute; top: 3px; left: 0; width: 12px; height: 12px; background: url('../images/material/dot.png') no-repeat center;}
                        a{color: #111111;}
                    }
                }
            }
        }
        .next-content{
            a{
                font-weight: 700; color: #333; line-height: 30px; @include transition(0.2s all); font-size: 16px;
                &:hover{color: $c_global;}
            }
            i{width: 6px; height: 12px; background: url('../images/material/next-content.png') no-repeat center; display: inline-block; margin-left: 5px;}
        }
    }

}
select.tbl-fortuna { min-width: 200px; margin-bottom: 13px !important; @include borderRadius(20px !important); }
select.style-2 { width: auto !important; font-size: 14px !important; padding: 10px 15px !important; margin: 0px; height: 40px !important; }
 .wrapper-fluid , .full-width { width: 100%; }
 .wrapper-fluid{
    p{font-size: 18px; line-height: 28px; font-weight: 300;color: #555;}    
 }
.no-margin{ margin:0px !important; }
.data-table-list{
    width: 100%;
    tr{ 
        border-bottom: 1px solid #d7d7d7;
        &.whitespace { height: 50px; }
        &.head-list{border-bottom: 3px solid $c_global;}
        &.head-list-2{border-bottom: 0px solid $c_global;
           th { padding: 3px 0 20px; position: relative; font-weight: 700; 
                &::after { display:none; }
           } 
        }
        &.title th { color: #fff;background: #36a5b3;font-size: 16px; padding: 15px; 
            &::after { display:none; }
        }
        th{
            padding: 3px 0 20px; position: relative; font-weight: 700; font-size: 16px; @include boxSizing(border-box);
            &:first-child{text-align: left; padding-left: 15px;}
            &:after{content: ""; position: absolute; top: 0; right: 0; height: 25px; background: #6adbe9; width: 1px;}
            &:last-child{
                &::after{display: none;}
            }
        }
        td{
            padding: 17px 0 29px; text-align: center; font-weight: 700; @include boxSizing(border-box);
            &:first-child{text-align: left; font-weight: 400; padding-left: 15px}
            span{
                &.up{color: #00a345;}
                &.down{color: #cd2026;}
            }
        }
        &.last-index{
            border-bottom: none;
            td{text-align: right; border-bottom: none;}
        }
    }

    &.fundhome{
        tr{
            th, td{
                &:first-child{width: 40%;}
                &:nth-child(2){width: 15%;}
                &:nth-child(3){width: 8%;}
                &:nth-child(4){width: 8%;}
                &:nth-child(5){width: 8%;}
                &:nth-child(6){width: 7%;}
                &:nth-child(7){width: 7%;}
                &:nth-child(8){width: 7%;}
            }
        }
    }

    &.fundreturn{
        tr{
            th, td{
                &:first-child{width: 32%;}
                &:nth-child(2){width: 15%;}
                &:nth-child(3){width: 8%;}
                &:nth-child(4){width: 8%;}
                &:nth-child(5){width: 8%;}
                &:nth-child(6){width: 7%;}
                &:nth-child(7){width: 7%;}
                &:nth-child(8){width: 7%;}
                &:nth-child(9){width: 7%;}
            }
        }
    }

    &.freeze-table{
        table-layout: fixed;border-collapse: collapse; width: 100%;
        thead{
            tr {
                position: relative;display: block;
                th, td{display: block; float: left;}
                @include afterclear;
            }
        }
        tbody{
            display: block; width: 100%; overflow: auto; height: 400px; 
            tr{
                position: relative;display: block;
                th, td{display: block; float: left;}
                @include afterclear;
            }
        }
    }
}
.text-right{
    text-align: right;
}
.mCSB_scrollTools {
    .mCSB_draggerRail {width: 1px; background: #ccc; margin: 0 12px;}
    .mCSB_dragger {
        .mCSB_dragger_bar {width: 8px; background: #ccc;}
        &:active {
            .mCSB_dragger_bar {background: #ccc;}
        }
        &.mCSB_dragger_onDrag {
            .mCSB_dragger_bar {background: #ccc;}
        }
        &:hover {
            opacity: 1;
            .mCSB_dragger_bar {background: #ccc;}
        }
    }
}
.mCSB_scrollTools a + .mCSB_draggerContainer{ margin: 0; }
.box_form{@include fullfloat; margin-bottom: 40px;}
.wrap-tab-perform{
    .box-selexbox-mb{display: none;}
}
.list-tab-prod{display: none;
    &.active{display: block;}
}
.sec-prod-highlight{
    background: #e7e7e7; padding: 87px 0; @include fullfloat;
    .box-prod-high{min-height: 700px;}
    .prod-left{width: 350px; float: left;
        .btn-htab{padding-left: 22px;}
        .box-tab-prod{width: 100%; margin-top: 23px;
            .list-tab-prod{display: none;
                &.active{display: block;}
                .box-tab-left{
                    position: relative;
                    .list-tab-left{
                        .text-list{
                            padding-left: 30px; padding-right: 20px; position: relative; height: 72px; border-bottom: 1px solid #cccccc; padding-top: 17px; position: relative;
                            &::before{content: ""; position: absolute; left: 0; top: 0; width: 11px; height: 74px; background: url('../images/material/tab-left.png') no-repeat center center; opacity: 0;}
                            &:hover{background: #dcdcdc; cursor: pointer;
                                &::before{opacity: 1;}
                            }
                            span{color: #208c9a; width: 100%; margin-bottom: 5px; display: inline-block;}
                            h4{font-weight: 700;}
                        }
                        .box-chart-highlight{
                            position: absolute; top: 0; left: 393px; width: 717px; min-height: 200px;display: none; background: #e7e7e7;
                            .title-data{
                                a{float: left; font-size: 14px; font-weight: 700; text-decoration: underline; color: $c_global; margin-left: 15px; margin-top: 10px;}
                            }
                            .highchart{margin-bottom: 22px; width: 100%; display: inline-block; overflow: auto;
                                .highchart-left{float: left; width: 180px; height: 400px; background: #fff; padding: 13px 16px;
                                    h3{font-size: 18px; color: #111111; font-weight: 700;}
                                    ul{
                                        li{
                                            position: relative; font-size: 14px; color: #555555; line-height: 24px; padding-left: 15px;
                                            &::before{content: ""; position: absolute; top: 8px; left: 0; background: $c_global; width: 8px; height: 8px;}
                                            span{font-weight: 700; color: #111;}
                                        }
                                    }
                                }
                                .highchart-right{float: right; width: 535px;}
                                .chart_fund{margin-bottom: 0;}
                                .RadHtmlChart{width: 717px;}
                            }
                            .data-table-list{
                                th, td{padding-bottom: 17px;}
                            }
                        }
                        &.active{
                            .text-list{
                                background: #dcdcdc; cursor: pointer;
                                &:before{opacity: 1;}
                            }
                            .box-chart-highlight{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .prod-right{
        width: 717px; float: right;
        .title-data{
            a{float: left; font-size: 14px; font-weight: 700; text-decoration: underline; color: $c_global; margin-left: 15px; margin-top: 10px;}
        }
        .highchart{margin-bottom: 22px; width: 100%; display: inline-block;
            .highchart-left{float: left; width: 180px; height: 400px; background: #fff; padding: 13px 16px;
                h3{font-size: 18px; color: #111111; font-weight: 700;}
                ul{
                    li{
                        position: relative; font-size: 14px; color: #555555; line-height: 24px; padding-left: 15px;
                        &::before{content: ""; position: absolute; top: 8px; left: 0; background: $c_global; width: 8px; height: 8px;}
                        span{font-weight: 700; color: #111;}
                    }
                }
            }
            .highchart-right{float: right; width: 535px;}
            .chart_fund{margin-bottom: 0;}
        }
        .data-table-list{
            th, td{padding-bottom: 17px;}
        }
    }
}
.row_list{
    margin-left: -15px; margin-right: -15px; 
    &:after{content: ""; display: block; clear: both;}
    .col{
        width: 100%; float: left; padding: 0 15px; @include boxSizing(border-box);
        &.fr{float: right;}
        &.no-pad{padding: 0;}
        &.marg28{margin-bottom: 28px;}
        &.col_7{width: 70%;}
        &.col_6{width: 60%;}
        &.col_55{width: 55%;}
        &.col_5{width: 50%;}
        &.col_45{width: 45%;}
        &.col_4{width: 40%;}
        &.col_35{width: 35%;}
        &.col_33{width: 33.3333333333333%;}
        &.col_3{width: 30%;}
        &.col_25{width: 25%;}
        &.col_2{width: 20%;}
        &.col_16{width: 16.66666666666666%;}
        &.col_15{width: 15%;}
        &.col_1{width: 10%;}
    }
    &.center{
        text-align: center;
        .col{float: none; display: inline-block;}
    }
}


.banner-middle{
    position: relative;
    .images{
        img{ width: 100%; }
    }
    .tittle-banner{
        position: absolute; right: 0; left: 0; bottom: 0; top: 0; width: 730px; height: 200px; margin: auto; text-align: center;
        h4{ font-size:24px; color: #fff;  margin-bottom: 15px; text-transform: uppercase; }
        span{ font-size: 55px; color: #fff;  }
    }
}
/* footer
----------------------------------------------------------------------------------------------*/
footer.new{
    border-top: 3px solid #36a5b3; padding-top: 25px; padding-bottom: 40px;
    .left{
        .cs{
            display: inline-block; width: 240px;
            @include afterclear;
            img{ float: left; }
            span{
                float: left; margin-left: 15px; font-size:14px; color:#36a5b3; font-size: 14px; font-family: $m; width: 170px; line-height: 16px;
                font-weight: 600;
            }
        }
        .wa{
            display: inline-block; width: 240px; margin-left: 15px;
            @include afterclear;
            span{
                float: left; margin-left: 15px; font-size:14px; color:#36a5b3; font-size: 14px; font-family: $m; width: 170px; line-height: 16px;
                font-weight: 600;
                margin-top: 12px;
            }
            img{
                float: left;
            }
        }
    }
    .right{
        span{
            text-align: left; display: inline-block; width: 215px; margin-left: 15px; font-size: 13px; color: #999; line-height: 20px;
        }
    }
}